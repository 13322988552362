export const WORDS = [
  'happy',
  'Capon',
  'Sushi',
  'Gassy',
  'AMVAT', 
  'Japan',
  'Scarf',
  'Walky',
  'Excel',
  'Pussy',
  'iiwii',
  'Paris',
  'Dance',
  'Vibes',
  'Trono',
  'Knits',
  'Yummy',
  'Korea',
  'Amble',
  'Bikes',
  'Bacne',
  'Safer',
  'Adore',
  'Texts',
  'Lover',
  'Silly',
  'Calls',
  'Great',
  'Blind',
  'Babby',
  'Sweat',
  'Hiked',
  'Biden',
  'Cream',
  'Night',
  'Daily',
  'Relax',
  'Knife', 
  'Climb',
  'Peace',
  'Watch',
  'Drive',
  'Views',
  'Music',
  'Crime',
  'Charm',
  'Brown',
  'Sport',
  'Smell',
  'Slice',
  'Ubers',
  'Marry',
  "Cuter",
  "Liver",
  "Winky",
  "Sings",
  "Ovary",
  "Hallo",
  "Swift",
  "Tulum",
  "Kpmgs",
  "Cafes",
].map(v => v.toLowerCase())
